import { getCurrencyByLocale } from './localization-utils';
import { PropertyPurpose } from 'types/application/application';
import { apiClient } from 'services/api';

export const isPurposeOwnerOccupied = (purpose?: PropertyPurpose) =>
    purpose === 'OWNER_OCCUPIED' || purpose === 'OWNER_OCCUPIED_AND_RENTAL';

export const getAvailableWithdrawalAmount = ({
    propertyValue,
    isOwnerOccupied,
    mortgageAmount,
    language = 'en'
}: {
    propertyValue: number;
    isOwnerOccupied: boolean;
    mortgageAmount: number;
    language?: string;
}) => {
    const percentage = isOwnerOccupied ? 0.8 : 0.75;

    const availableAmount = propertyValue * percentage - mortgageAmount;

    const total = availableAmount > 0 ? availableAmount : 0;
    const formattedTotal = getCurrencyByLocale(language, total, {
        maximumFractionDigits: 0
    });

    return formattedTotal;
};

export const getIsPhoneNumberReal = async (
    phone: string,
    isPhoneNumber: boolean
) => {
    const { data, ok } = await apiClient.validatePhoneNumber(phone);

    if (ok) {
        const { valid } = data as { valid: boolean };
        const isPhoneNumberReal = valid && isPhoneNumber;
        return isPhoneNumberReal;
    } else {
        return false;
    }
};
