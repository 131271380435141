import CMLS from 'assets/media/logos/lenders/CMLS.png';
import FNF from 'assets/media/logos/lenders/First_National.png';
import Lendwise from 'assets/media/logos/lenders/Lendwise.png';
import MCAP from 'assets/media/logos/lenders/MCAP.png';
import Nesto from 'assets/media/logos/lenders/nesto.png';
import Merix from 'assets/media/logos/lenders/Merix.png';
import Scotiabank from 'assets/media/logos/lenders/Scotia_bank.png';
import StreetCapital from 'assets/media/logos/lenders/Street_capital.png';
import TD from 'assets/media/logos/lenders/TD.png';
import EquitableBank from 'assets/media/logos/lenders/nesto.png';
import EquitableBankRealLogo from 'assets/media/logos/lenders/Equitable_bank.png';
import Desjardins from 'assets/media/logos/lenders/Desjardins.png';
import Alterna from 'assets/media/logos/lenders/Alterna.png';
import WealthOne from 'assets/media/logos/lenders/Wealth_one.png';
import XMC from 'assets/media/logos/lenders/XMC.png';
import Duca from 'assets/media/logos/lenders/duca.png';
import UNI from 'assets/media/logos/lenders/UNI.png';
import ICICI from 'assets/media/logos/lenders/ICICI.png';

export const LENDER_SELECT_OPTIONS = [
    {
        value: 'DESJARDINS',
        label: 'lender.desjardins'
    },
    {
        value: 'SCOTIABANK',
        label: 'lender.scotia'
    },
    {
        value: 'LAURENTIAN',
        label: 'lender.laurentian'
    },
    {
        value: 'TD',
        label: 'lender.td'
    },
    {
        value: 'RBC',
        label: 'lender.rbc'
    },
    {
        value: 'CIBC',
        label: 'lender.cibc'
    },
    {
        value: 'NATIONAL_BANK',
        label: 'lender.nationalBank'
    },
    {
        value: 'BMO',
        label: 'lender.bmo'
    },
    {
        value: 'MCAP',
        label: 'lender.mcap_display'
    },
    {
        value: 'TANGERINE',
        label: 'lender.tangerine'
    },
    {
        value: 'B2B_LAURENTIAN',
        label: 'lender.b2b_laurentian'
    },
    {
        value: 'FIRST_NATIONAL',
        label: 'lender.first_national'
    },
    {
        value: 'EQUITABLE_BANK',
        label: 'lender.equitable_bank'
    },
    {
        value: 'STREET_CAPITAL',
        label: 'lender.street_capital'
    },
    {
        value: 'LENDWISE',
        label: 'lender.lendwise'
    },
    {
        value: 'MERIX',
        label: 'lender.merix'
    },
    {
        value: 'CMLS',
        label: 'lender.cmls'
    },
    {
        value: 'ALTERNA',
        label: 'lender.alterna'
    },
    {
        value: 'RADIUS_FINANCIAL',
        label: 'lender.radiusFinancial'
    },
    {
        value: 'DUCA',
        label: 'lender.duca'
    },
    {
        value: 'HOME_TRUST',
        label: 'lender.homeTrust'
    },
    {
        value: 'ICICI_BANK',
        label: 'lender.iciciBank'
    },
    {
        value: 'INDUSTRIAL_ALLIANCE',
        label: 'lender.industrialAlliance'
    },
    {
        value: 'MANULIFE',
        label: 'lender.manulife'
    },
    {
        value: 'MEREDIAN',
        label: 'lender.meredian'
    },
    {
        value: 'OPTIMUM_MORTGAGE',
        label: 'lender.optimumMortgage'
    },
    {
        value: 'PC_FINANCIALS',
        label: 'lender.pcFinancials'
    },
    {
        value: 'PRIVATE',
        label: 'lender.private'
    },
    {
        value: 'OTHER',
        label: 'other'
    }
];

export const LENDER_OPTIONS = {
    DESJARDINS: 'DESJARDINS',
    SCOTIABANK: 'SCOTIABANK',
    LAURENTIAN: 'LAURENTIAN',
    TD: 'TD',
    RBC: 'RBC',
    CIBC: 'CIBC',
    NATIONAL_BANK: 'NATIONAL_BANK',
    BMO: 'BMO',
    MCAP: 'MCAP',
    TANGERINE: 'TANGERINE',
    B2B_LAURENTIAN: 'B2B_LAURENTIAN',
    FIRST_NATIONAL: 'FIRST_NATIONAL',
    EQUITABLE_BANK: 'EQUITABLE_BANK',
    STREET_CAPITAL: 'STREET_CAPITAL',
    LENDWISE: 'LENDWISE',
    MERIX: 'MERIX',
    CMLS: 'CMLS',
    ALTERNA: 'ALTERNA',
    RADIUS_FINANCIAL: 'RADIUS_FINANCIAL',
    DUCA: 'DUCA',
    HOME_TRUST: 'HOME_TRUST',
    ICICI_BANK: 'ICICI_BANK',
    INDUSTRIAL_ALLIANCE: 'INDUSTRIAL_ALLIANCE',
    MANULIFE: 'MANULIFE',
    MEREDIAN: 'MEREDIAN',
    OPTIMUM_MORTGAGE: 'OPTIMUM_MORTGAGE',
    PC_FINANCIALS: 'PC_FINANCIALS',
    PRIVATE: 'PRIVATE',
    OTHER: 'OTHER'
};

type LenderDetails = {
    lenderId?: number;
    namePath: string;
    descriptionPath: string;
    imagePath: string;
};

export const LENDER_INFO: Record<number | string, LenderDetails> = {
    3: {
        lenderId: 3,
        namePath: 'lender.cmls',
        descriptionPath: 'bankInfo.cmlsFinancial.description',
        imagePath: CMLS
    },
    4: {
        lenderId: 4,
        namePath: 'bankInfo.firstNationalFinancial.name',
        descriptionPath: 'bankInfo.firstNationalFinancial.description',
        imagePath: Nesto
    },
    7: {
        lenderId: 7,
        namePath: 'lender.lendwise',
        descriptionPath: 'bankInfo.lendwise.description',
        imagePath: Lendwise
    },
    8: {
        lenderId: 8,
        namePath: 'lender.mcap',
        descriptionPath: 'bankInfo.mcapFinancial.description',
        imagePath: Nesto
    },
    9: {
        lenderId: 9,
        namePath: 'lender.merix',
        descriptionPath: 'bankInfo.merixFinancial.description',
        imagePath: Merix
    },
    12: {
        lenderId: 12,
        namePath: 'bankInfo.scotiabank.name',
        descriptionPath: 'bankInfo.scotiabank.description',
        imagePath: Scotiabank
    },
    13: {
        lenderId: 13,
        namePath: 'lender.street_capital',
        descriptionPath: 'bankInfo.streetCapitalBank.description',
        imagePath: StreetCapital
    },
    14: {
        lenderId: 14,
        namePath: 'lender.td',
        descriptionPath: 'bankInfo.tdBank.description',
        imagePath: TD
    },
    16: {
        lenderId: 16,
        namePath: 'lender.trustCompany',
        descriptionPath: 'bankInfo.equitableBank.description',
        imagePath: EquitableBank
    },
    26: {
        lenderId: 26,
        namePath: 'lender.desjardins',
        descriptionPath: 'bankInfo.desjardins.description',
        imagePath: Desjardins
    },
    27: {
        lenderId: 27,
        namePath: 'bankInfo.duca.name',
        descriptionPath: 'bankInfo.duca.description',
        imagePath: Duca
    },
    53: {
        lenderId: 53,
        namePath: 'lender.alterna',
        descriptionPath: 'bankInfo.alterna.description',
        imagePath: Alterna
    },
    54: {
        lenderId: 54,
        namePath: 'bankInfo.wealthOneBank.name',
        descriptionPath: 'bankInfo.wealthOneBank.description',
        imagePath: WealthOne
    },
    60: {
        lenderId: 60,
        namePath: 'bankInfo.xmc.name',
        descriptionPath: 'bankInfo.xmc.description',
        imagePath: XMC
    },
    1001: {
        lenderId: 1001,
        namePath: 'lender.equitable_bank',
        descriptionPath: 'bankInfo.equitableBank.description',
        imagePath: EquitableBankRealLogo
    },
    '*': {
        namePath: 'lender.generic',
        descriptionPath: 'bankInfo.generic.description',
        imagePath: Nesto
    }
};

export const LENDER_INFO_REAL_NAMES: Record<number | string, LenderDetails> = {
    ...LENDER_INFO,
    // Those next lender have hidden name on LENDER_INFO for `/rates` page
    // in App summary we need to show the real name
    4: {
        lenderId: 4,
        namePath: 'bankInfo.firstNationalFinancial.name_display',
        descriptionPath: 'bankInfo.firstNationalFinancial.description',
        imagePath: FNF
    },
    8: {
        lenderId: 8,
        namePath: 'lender.mcap_display',
        descriptionPath: 'bankInfo.mcapFinancial.description',
        imagePath: MCAP
    },
    1000: {
        lenderId: 1000,
        namePath: 'lender.uni',
        descriptionPath: 'bankInfo.uni.description',
        imagePath: UNI
    },
    6: {
        lenderId: 6,
        namePath: 'lender.iciciBank',
        descriptionPath: 'bankInfo.icici.description',
        imagePath: ICICI
    },
    16: {
        lenderId: 16,
        namePath: 'lender.equitable_bank',
        descriptionPath: 'bankInfo.equitableBank.description',
        imagePath: EquitableBankRealLogo
    }
};

export const getLender = (lenderId: number, useRealName = false) =>
    useRealName
        ? LENDER_INFO_REAL_NAMES[lenderId] || LENDER_INFO_REAL_NAMES['*']
        : LENDER_INFO[lenderId] || LENDER_INFO['*'];
